import { defineStore } from 'pinia';
import { useRuntimeConfig } from '#app';
import { useAuthStore } from '~~/stores/AuthStore';

const { checkStatusCode } = useCheckRequestStatusCode();

export const usePostponeSessionStore = defineStore('postponeSession', {
  state: () => ({
    PostponeSession: null,
    coachAvailableAppointments: null,
    loading: false,
    serverErrors: {},
  }),
  actions: {
    async update(payload) {
      const authStore = useAuthStore();
      this.serverErrors = {};
      this.loading = true;
      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('status', payload.data);

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/accept-postpone-session/${
          payload.id
        }`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value.message;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data.message;
      }
    },
    async store(payload) {
      const authStore = useAuthStore();
      this.loading = true;
      const formData = new FormData();
      for (const key in payload) {
          formData.append(key, payload[key]);
      }

      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/postpone-session`,
        {
          method: 'POST',
          headers: authStore.auth.headers,
          body: formData,
        }
      );
      this.loading = false;
      if (!error.value) {
        return response.value;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
    async getCoachAvailableAppointments(payload) {
      const authStore = useAuthStore();
      this.loading = true;
      const { data: response, error } = await useFetch(
        `${useRuntimeConfig().public.baseUrl}/coach-available-appointments/${payload.id}?page=${payload.page}`,
        {
          method: 'GET',
          headers: authStore.auth.headers,
        }
      );
      this.loading = false;
      if (!error.value) {
        this.coachAvailableAppointments = response.value.data;
      } else {
        checkStatusCode(error.value.data.status_code);
        this.serverErrors = error.value.data.errors;
        return error.value.data;
      }
    },
  },
});
